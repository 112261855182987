<template>
    <div class="d-flex">
        <v-text-field
            hide-details
            append-icon="mdi-magnify"
            placeholder="ricerca"
            single-line
            v-model="search"
            autofocus
            outlined
            rounded
            dense
        ></v-text-field>
        <v-menu
            offset-y
            :close-on-click="closeOnClick"
            :close-on-content-click="closeOnContentClick"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="mx-2"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :retain-focus-on-click="true"
                >
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>
            <component :is="loadAsyncComponent"></component>
        </v-menu>
        <v-btn-toggle
            dense
            rounded
            v-model="farmListType"
            mandatory
            v-if="showFarmIcon"
        >
            <v-btn outlined color="green">
                <v-icon>mdi-table-of-contents</v-icon>
                In App
            </v-btn>
            <v-btn outlined color="green">
                <v-icon>mdi-storefront</v-icon>
                Negozi
            </v-btn>
            <v-btn outlined color="green">
                <v-icon>mdi-database</v-icon>
                Clusters
            </v-btn>
            <v-btn color="primary" outlined>
                <v-icon>mdi-file-outline</v-icon>
                Da Approvare
            </v-btn>
        </v-btn-toggle>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import * as appActions from "../../store/actions/app";
import * as filterActions from "../../store/actions/filter";

export default {
    data: () => ({
        closeOnClick: false,
        closeOnContentClick: false
    }),

    computed: {
        ...mapState({
            getFarmListType: state => state.appModule.farmListType,
            searchPromoString: state => state.filterModule.promoFilter.text,
            searchMultiFarmsPromoString: state => state.filterModule.multiFarmsFilter.text,
            searchStoreString: state => state.filterModule.storeFilter.text,
            searchPromeFileString: state => state.filterModule.promeFileFilter.text,
            searchClustersString: state => state.filterModule.clustersFilter.text
        }),

        search: {
            get() {
                if (this.$route.name === 'Multi') {
                    return this.searchMultiFarmsPromoString
                }
                else if (this.farmListType === 0 || this.$route.name == 'StorePromos') {
                    return this.searchPromoString
                }
                else if (this.farmListType === 1) {
                    return this.searchStoreString;

                }
                else if(this.farmListType === 2) {
                    return this.searchClustersString
                }
                else {
                    return this.searchPromeFileString
                }
            },
            set(newValue) {
                if (this.$route.name === 'Multi') {
                    this.updateMultiFarmsPromoFilterText(newValue);
                }
                else if (this.farmListType === 0 || this.$route.name == 'StorePromos') {
                    this.updatePromoFilterText(newValue);
                } else if (this.farmListType === 1){
                    this.updateStoreFilterText(newValue);
                }
                else if (this.farmListType === 2) {
                    this.updateClustersFilterText(newValue)
                }
                else this.updatePromeFileFilterText(newValue)
            }
        },

        loadAsyncComponent() {
            if (this.$route.name === 'Multi') {
                return () => ({
                    component: import("./PromoMultiFarmsMenuFilter"),
                    //   loading: AwesomeSpinner,
                    //   error: SadFaceComponent,
                    timeout: 1000
                });
            }
            else if (this.getFarmListType === 0 || this.$route.name == 'StorePromos') {
                return () => ({
                    component: import("./PromoMenuFilter"),
                    //   loading: AwesomeSpinner,
                    //   error: SadFaceComponent,
                    timeout: 1000
                });
            }
            else if (this.getFarmListType === 1) {
                return () => ({
                    component: import("./PromoMenuFilter"),
                    // component: import("./StoreMenuFilter"),
                    //   loading: AwesomeSpinner,
                    //   error: SadFaceComponent,
                    timeout: 1000
                });
            }
            else {
                return () => ({
                    component: import("./PromeFileMenuFilter"),
                    //   loading: AwesomeSpinner,
                    //   error: SadFaceComponent,
                    timeout: 1000
                });
            }
        },

        showFarmIcon() {
            return this.$route.name === "Farm" ? true : false;
        },

        farmListType: {
            get: function() {
                return this.getFarmListType;
            },
            set: function(newValue) {
                this.setFarmListType(newValue);
            }
        }
    },

    methods: {
        ...mapMutations({
            setFarmListType: appActions.APP_SETFARMLISTTYPE,
            updatePromoFilterText: filterActions.FILTER_PROMOS_SET_TEXT,
            updateMultiFarmsPromoFilterText: filterActions.FILTER_MULTIFARMSPROMOS_SET_TEXT,
            updateStoreFilterText: filterActions.FILTER_STORES_SET_TEXT,
            updatePromeFileFilterText: filterActions.FILTER_PROMEFILES_SET_TEXT,
            updateClustersFilterText: filterActions.FILTER_CLUSTERS_SET_TEXT
        })
    },

    mounted() {
        console.log(this.$route);
    }
};
</script>

<style></style>
